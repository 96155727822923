<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="Box Express 🚀">
      <b-card-text>Statistique de livraison</b-card-text>
    </b-card>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-container fluid>
      <b-badge
        v-if="isFreeTrial"
        variant="warning"
      >
        Essai gratuit! Déverrouillez toutes les fonctionnalités en mettant à niveau votre plan.
      </b-badge>
      <h2>
        Analyse Mensuelle : Suivi du Chiffre d'Affaires par Mois
      </h2>
      <p>un aperçu mensuel du chiffre d'affaires basé sur le total des prix TTC des commandes. Les statistiques sont regroupées par mois, permettant une analyse approfondie des performances de vente au fil du temps.</p>
      <div>
        <b-table
          striped
          hover
          :items="monthlyRecap"
          :fields="monthlyRecapFields"
          :thead-variant="'dark'"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import {
  BCard, BCardText,
  BSpinner,
} from 'bootstrap-vue'

import axios from 'axios'
import storeAuth from '@/store/store'

export default {
  name: 'Dashboard',
  components: {
    BCard,
    BCardText,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      user: {},
      // order summary KPIs
      monthlyRecap: [],
      monthlyRecapFields: [
        { key: 'month', label: 'Mois' },
        { key: 'order_count', label: 'Nombre de commandes' },
        { key: 'price_ttc_sum', label: 'Chiffre d\'affaires (TND)' },
      ],
      isFreeTrial: true,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    }
  },
  created() {
    this.user = storeAuth.state.user
    if (this.user.role === 'admin') {
      this.fetchMonthlyRecap()
    }
  },
  methods: {
    async fetchMonthlyRecap() {
      try {
        this.isLoading = true
        const { data } = await axios.get(`/api/kpi/orders/monthly-summary/?year=${this.year}`)
        this.monthlyRecap = data
        this.isLoading = false
      } catch (error) {
        console.error('Error fetching monthly recap:', error)
      }
    },
    show(level) {
      this.$router.push({ name: 'order-list', params: { level } })
    },
  },
}
</script>
    <style lang="scss" scoped>
    @import '@core/scss/vue/libs/vue-good-table.scss';

    .b-card {
      border: 1px solid #dee2e6;
      border-radius: .25rem;
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
      margin-bottom: 1rem;
      background-color: #fff;
      padding: 1rem;
    }

    .b-badge {
      font-size: 14px;
    }
    </style>
